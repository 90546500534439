import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
body, button, dd, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, input, legend, li, ol, p, select, table, td, textarea, th, ul, h1, h2, h3, h4, h5, h6, figure, figcaption, section, article, pre {
  margin:0;
  padding:0;
  /* pretendard */
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, Roboto, system-ui,  "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
};

body {
  overflow: scroll;
  user-select: none;
}

button, input, select, option textarea {
};

button {
  border: 1px solid transparent;
  outline: none;
  background-color:transparent;
  cursor: pointer;
}

input {
  outline: none;
}

ol, ul {
  list-style:none;
}

a {
  color:inherit;
  text-decoration:none;
}

a:hover {
  text-decoration:underline;
}

/* ReactQuill remove border */
.ql-container.ql-snow{
  border: none;
}


// slick list 화면 나갈 시 잘리는 거 방지
.slick-list{
  overflow:visible
}

.ql-editor {
  font-size: 18px; /* 원하는 폰트 사이즈로 변경하세요 */
  padding-left: 0px;
  padding-right: 0px;
}

.ql-video {
  width: 640px;
  height: 360px;
}

`;

export default GlobalStyles;
