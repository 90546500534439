import { Headline2, Paragraph2 } from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  display: none;
  background-color: ${({ theme }) => theme.color.beige};
  padding: 200px 0px 150px 0px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 120px 0px 80px 0px;
    display: block;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 60px 0px 60px 0px;
    padding-bottom: 60px;
  }
`;
const StyledInnerWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 180px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    align-items: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    align-items: center;
    gap: 50px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 86px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    gap: 32px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 25px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    gap: 19px;
  }
`;

const StyledContentCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 19px;
  }
`;

const StyledTitle = styled(Headline2)`
  line-height: 80px;
  margin-top: 25px;
  margin-bottom: 44px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    margin: 0;
    font-size: 44px !important;
    line-height: 60px !important;
    text-align: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px !important;
    line-height: 34px !important;
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;
  }
`;
const StyledComment = styled(Paragraph2)`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    text-align: center;
    font-size: 22px !important;
    line-height: 45px !important;
    letter-spacing: -1px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    text-align: center;
    font-size: 18px !important;
    line-height: 34px !important;
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;
  }
`;

const StyledImageWrapper = styled.div`
  width: 575px;
  height: 360px;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    width: 326px;
    height: 200px;
    & > img {
      width: 326px;
      height: 200px;
    }
  }
`;

const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: block;
  }
`;

export {
  StyledWrapper,
  StyledContainer,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledImageWrapper,
  StyledContentCon,
  StyledInnerWrapper,
  StyledBr,
  StyledResponsiveBr,
};
