import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const usePathname = () => {
  const { pathname } = useLocation();
  const [firstPath, setFirstPath] = useState<string>("");
  const [lastPath, setLastPath] = useState<string>("");

  const removeDynamicPathname = (_pathname: string) => {
    return _pathname.replace("/[id]", "");
  };

  useEffect(() => {
    const processedPathname = removeDynamicPathname(pathname);
    const splitPathnameForSlash = processedPathname.split("/");
    if (splitPathnameForSlash.length >= 2) {
      setFirstPath(`/${splitPathnameForSlash[1]}`);
    }
    setLastPath(`/${splitPathnameForSlash[splitPathnameForSlash.length - 1]}`);
  }, [pathname]);

  return { firstPath, lastPath, pathname };
};

export default usePathname;
