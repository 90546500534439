import styled from "styled-components";

interface StyledLogoWrapperProps {
  size: "large" | "medium" | "small";
}

const StyledLogoWrapper = styled.div<StyledLogoWrapperProps>`
  display: inline-flex;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    img {
      width: 100px;
      height: 20px;
    }
  }
`;

const StyledLogo = styled.img`
  display: block;
`;

export { StyledLogoWrapper, StyledLogo };
