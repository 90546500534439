import { useNavigate } from "react-router-dom";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledContentCon,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";
import Button from "../../../../components/button";

const Banner: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledContentCon>
          <StyledTitle>지금 이지마스를 경험해보세요!</StyledTitle>
          <StyledComment>
            군 급식 발주를 더 쉽게, 더 정확하게 관리하고 싶다면,
            <StyledResponsiveBr /> 지금 바로 이지마스에 가입하세요.
            <StyledResponsiveBr /> <StyledBr />군 급식 발주의 효율성을 높이는
            첫걸음이 될 것입니다!
          </StyledComment>
        </StyledContentCon>
        <div>
          <Button
            onClick={() => window.open('https://vendor.easymas.kr', '_blank')}
            type="button"
            size="full"
            color="representative"
            text="무료로 시작하기"
          />
        </div>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

export default Banner;
