import React from "react";
import {
  StyledComment,
  StyledReviewCardContent,
  StyledReviewCardContentsWrapper,
  StyledReviewCardWrapper,
  StyledSpan,
  StyledTitle,
  // StyledState,
} from "./styles";
import { APP_REVIEW } from "../../page-bodies/client/components/app-review/review";

interface ReviewProps {
  review: APP_REVIEW;
}

const ReviewCard: React.FC<ReviewProps> = ({ review }) => {
  return (
    <StyledReviewCardWrapper>
      <StyledReviewCardContentsWrapper>
        <StyledSpan>
          {review.job} {review.name}
        </StyledSpan>
        <StyledReviewCardContent>
          <StyledTitle>{review.title}</StyledTitle>
          <StyledComment>{review?.comment}</StyledComment>
        </StyledReviewCardContent>
      </StyledReviewCardContentsWrapper>
    </StyledReviewCardWrapper>
  );
};

export default ReviewCard;
