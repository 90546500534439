import {
  StyledWrapper,
  StyledContentWrapper,
  StyledContainer,
  StyledTitle,
  StyledItemList,
  StyledSlider,
  StyledReverseSlider,
} from "./styles";
import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
const itemList = [0, 1, 2, 3, 4, 5];
const Carousel: React.FC = () => {
  const { ref, isInViewport } = useScrollAnimation();

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledContentWrapper ref={ref} $isFadeActive={isInViewport}>
          <StyledTitle>다양한 식품 카테고리를 확인해보세요!</StyledTitle>
        </StyledContentWrapper>
      </StyledContainer>
      <StyledItemList>
        <StyledSlider>
          <div>
            {itemList?.map((item) => (
              <picture key={item}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item1-${item}`}
                />
              </picture>
            ))}
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item1-${item}`}
                />
              </picture>
            ))}
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item1-${item}`}
                />
              </picture>
            ))}
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item1-${item}`}
                />
              </picture>
            ))}
          </div>
        </StyledSlider>

        <StyledReverseSlider>
          <div>
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`funechoItme2-${item}`}
                />
              </picture>
            ))}
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item2-${item}`}
                />
              </picture>
            ))}
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item2-${item}`}
                />
              </picture>
            ))}
            {itemList?.map((item) => (
              <picture key={`${item}-2`}>
                <source
                  srcSet={`/images/client/carousel/item1-${item}.png`}
                  media="(min-width: 769px)"
                />
                <img
                  src={`/images/client/carousel/item1-${item}M.png`}
                  alt={`item2-${item}`}
                />
              </picture>
            ))}
          </div>
        </StyledReverseSlider>
      </StyledItemList>
    </StyledWrapper>
  );
};

export default Carousel;
