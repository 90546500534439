import {
  StyledMainWrapper,
  StyledWrapperTop,
  StyledWrapperBottom,
  StyledContentWrapper1,
  StyledContentWrapper2,
  StyledTitle,
  StyledSubTitle,
  StyledBr,
  StyledImageWrapper,
  StyledResponsiveBr,
} from "./styles";
import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import Button from "../../../../components/button";
import { useNavigate } from "react-router-dom";

const ServiceSummary = () => {
  const navigate = useNavigate();
  const { ref, isInViewport } = useScrollAnimation();

  return (
    <StyledMainWrapper>
      <StyledWrapperTop ref={ref} $isFadeActive={isInViewport}>
        <StyledContentWrapper1>
          <Button
            onClick={() => navigate("/marketing")}
            type="button"
            size="large"
            color="representative-reverse"
            text="이지마스 소개"
            radius="5px"
          />
          <StyledTitle>
            군부대가 여러분의 제품을 <StyledResponsiveBr />
            <StyledBr /> 더 쉽게 찾고, 주문하도록 <StyledBr />
            돕습니다.
          </StyledTitle>
          <StyledImageWrapper>
            <picture>
              <source
                srcSet="/images/company/summary1.png"
                media="(min-width: 769px)"
              />
              <img
                srcSet="/images/company/summary1M.png"
                alt="images/mainSection1.png"
              />
            </picture>
          </StyledImageWrapper>
        </StyledContentWrapper1>
      </StyledWrapperTop>

      <StyledWrapperBottom ref={ref} $isFadeActive={isInViewport}>
        <StyledContentWrapper2>
          <StyledImageWrapper>
            <picture>
              <source
                srcSet="/images/company/summary2.png"
                media="(min-width: 769px)"
              />
              <img
                srcSet="/images/company/summary2M.png"
                alt="images/mainSection1.png"
              />
            </picture>
          </StyledImageWrapper>
          <StyledSubTitle>
            이지마스는 군부대와 식품업체를 연결하는
            <br /> 통합 발주 시스템입니다. 군부대 담당자는 이지마스를 통해
            <br /> 다양한 제품을 검색하고, 필요한 정보를 바로 확인할 수
            있습니다.
          </StyledSubTitle>
        </StyledContentWrapper2>
      </StyledWrapperBottom>
    </StyledMainWrapper>
  );
};

export default ServiceSummary;
