import { useCallback, useEffect, useRef, useState } from "react";

const HEADER_HEIGHT = 100;
const SPACE = 800;

const handleIntersection =
  (
    target: string,
    changeActivateIdCallback: (target: string, type: "ADD" | "REMOVE") => void,
    setRerenderTrigger: (e: any) => void
  ) =>
  (
    entries: IntersectionObserverEntry[],
    option: IntersectionObserverInit
  ): void => {
    entries.forEach((entry) => {
      changeActivateIdCallback(target, entry.isIntersecting ? "ADD" : "REMOVE");
      setRerenderTrigger({});
    });
  };

const useScrollObserver = (targets: string[]) => {
  const [activateElementId, setActivateElementId] = useState<string[]>([]);
  const [rerenderTrigger, setRerenderTrigger] = useState();
  const list = useRef<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const clickScrollNavigation = (target: string) => {
    if (wrapperRef?.current == null) {
      throw new Error("target id is not definded");
    }
    const targetElement = wrapperRef.current.querySelector(`#${target}`);
    if (targetElement == null) {
      throw new Error("target element id is not definded");
    }
    window.scrollTo({
      top:
        window.scrollY +
        targetElement?.getBoundingClientRect().top -
        HEADER_HEIGHT,
      behavior: "smooth",
    });
  };

  const changeActivateIdCallback = (target: string, type: "ADD" | "REMOVE") => {
    if (type === "ADD") {
      const findTarget = list.current.find((id) => id === target);
      if (findTarget != null) {
        return;
      }
      list.current.push(target);
    }
    if (type === "REMOVE") {
      const idx = list.current.findIndex((id) => id === target);
      if (idx == null || idx === -1) {
        return;
      }
      list.current.splice(idx, 1);
    }
  };

  useEffect(() => {
    if (wrapperRef.current == null) {
      return;
    }
    const observers = targets.map((target) => {
      const targetElement = wrapperRef.current?.querySelector(`#${target}`);
      if (targetElement == null) {
        throw new Error("target element id is not defined");
      }
      const io = new IntersectionObserver(
        handleIntersection(
          target,
          changeActivateIdCallback,
          setRerenderTrigger
        ),
        { threshold: 0.3 }
      );
      io.observe(targetElement);
      return io;
    });

    return () => {
      observers.map((observer) => observer.disconnect());
    };
  }, [wrapperRef]);

  const getActivateTarget = useCallback(() => {
    if (list.current.length === 0) {
      return "";
    }
    return list.current[list.current.length - 1];
  }, [rerenderTrigger]);

  return {
    wrapperRef,
    clickScrollNavigation,
    activateElementId,
    getActivateTarget,
    rerenderTrigger,
  };
};
export default useScrollObserver;
