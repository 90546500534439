import {
  Button1,
  Headline2,
  Headline3,
  Paragraph2,
  Paragraph3,
} from "../../../../styles/typography";
import { keyframes } from "styled-components";
import { styled, css } from "styled-components";

const frameInAnimationTop = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
`;

interface Props {
  $isFadeActive?: boolean;
  $active?: 1 | 0;
}

const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;

const StyledMainWrapper = styled.section`
  display: flex;
  gap: 150px;
  justify-content: center;
  overflow: hidden;
  padding: 106px 0 83px 0;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(255, 117, 17, 0.4) 97%,
    rgba(255, 107, 0, 0.3) 100%
  );
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 60px 0 44px 0;
    background: linear-gradient(
      180deg,
      rgba(255, 117, 17, 0.2) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 117, 17, 0.4) 97%,
      rgba(255, 107, 0, 0.3) 100%
    );
  }
`;

const StyledWrapperTop = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 68px;
  transform: translateY(20%);
  justify-content: center;
  box-sizing: border-box;
  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimationTop} 0.8s forwards ease-in-out;
    `}

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 42px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
  align-items: center;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    margin-top: 33px;
    max-width: 360px;
    gap: 21px;
    & > img {
      width: 360px;
      height: 100%;
    }
  }
`;

const StyledTitle = styled(Headline3)`
  line-height: 60px;
  word-break: keep-all;
  text-align: center;
  word-wrap: break-word;
  white-space: pre-line;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-weight: 700;
    font-size: 22px !important;
    line-height: 34px !important;

    max-width: 231px;
  }
`;

const StyledSubTitle = styled(Paragraph3)`
  text-align: center;
  line-height: 40px;
  margin: 0;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    margin-top: 21px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0 15px;
    margin: 0;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 22px !important;
    white-space: pre-line;
    word-break: keep-all;
    text-align: center;
    word-wrap: break-word;
  }
`;

export {
  StyledMainWrapper,
  StyledWrapperTop,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledBr,
  StyledResponsiveBr,
};
