export type APP_REVIEW = {
  job: string;
  name: string;
  title: string;
  comment: string;
};
export const APP_REVIEW_LIST = [
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
  {
    job: "영업사",
    name: "홍길동",
    title: "이지마스 사용하니까 너무 좋아요!!",
    comment:
      "설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.설명입니다.",
  },
];
