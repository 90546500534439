import {
  StyledFooter,
  StyledWebSiteInfoWrapper,
  StyledInfo,
  StyledInfoContour,
  StyledTitle,
  StyledLogoWrapper,
  StlyedPolicyWrapper,
  StyledPolicy,
  StyledInfoWrapper,
  StyledCopyrightWrapper,
  StyledCopyright,
  StyledFlexWrapper,
  StyledWrpper,
  StyeldInfoCon,
  StlyedPolicyMobileWrapper,
} from "./style";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledWrpper>
        <StyledWebSiteInfoWrapper>
          <StyledFlexWrapper>
            <StyledLogoWrapper>
              <picture>
                <source
                  srcSet="/images/footerLogo.png"
                  media="(min-width: 769px)"
                />
                <img
                  srcSet="/images/footerLogo.png"
                  alt="images/footerLogo.png"
                />
              </picture>
            </StyledLogoWrapper>
            <StlyedPolicyMobileWrapper>
              {/* 
              <StyledPolicy>개인정보처리방침</StyledPolicy>
              <StyledPolicy>이용약관</StyledPolicy>
              <StyledPolicy>광고 운영정책</StyledPolicy>
              <StyledPolicy>상품판매 운영정책</StyledPolicy>
              */}
            </StlyedPolicyMobileWrapper>
            <StyeldInfoCon>
              <StyledInfoWrapper>
                <StyledTitle>제이앤제이 스토리</StyledTitle>
                <StyledInfo>대표 이정웅</StyledInfo>
                <StyledInfoContour />
                <StyledInfo>사업자등록번호 481-52-91969</StyledInfo>
                <StyledInfoContour />
                <StyledInfo>강원특별자치도 홍천군 홍천읍 301</StyledInfo>
              </StyledInfoWrapper>
              <StyledInfoWrapper>
                <StyledInfo>
                  고객센터 대응센터 : 서울특별시 서초구 방배로 230-1,
                  9층(방배동, 융성빌딩)
                </StyledInfo>
              </StyledInfoWrapper>
              <StyledInfoWrapper>
                <StyledInfo>
                  T. 02.536-3742 / F.02-536-3334 / E-mail. admin@easymas.kr
                </StyledInfo>
              </StyledInfoWrapper>
            </StyeldInfoCon>
          </StyledFlexWrapper>
          <StyledCopyrightWrapper>
            <StyledCopyright>
              Copyright 2024 by J&J STORY. All rights reserved
            </StyledCopyright>
          </StyledCopyrightWrapper>
        </StyledWebSiteInfoWrapper>
        <StlyedPolicyWrapper>
          {/* 
          <StyledPolicy>개인정보처리방침</StyledPolicy>
          <StyledPolicy>이용약관</StyledPolicy>
          <StyledPolicy>광고 운영정책</StyledPolicy>
          <StyledPolicy>상품판매 운영정책</StyledPolicy>
          */}
        </StlyedPolicyWrapper>
      </StyledWrpper>
    </StyledFooter>
  );
};

export default Footer;
