import { StyledSlideArrow } from "./styles";
import { Theme, Size } from "./types";
import { ReactComponent as NextArrow } from "../../assets/icons/nextArrow.svg";
import { ReactComponent as PrevArrow } from "../../assets/icons/prevArrow.svg";

interface SlideArrowProps extends React.HTMLAttributes<HTMLDivElement> {
  theme: Theme;
  size: Size;
  direction: "prev" | "next";
  onClick: () => void;
  svgSize: number;
}

const SlideArrowButton: React.FC<SlideArrowProps> = ({
  theme,
  size,
  direction,
  onClick,
  svgSize,
  ...props
}) => {
  return (
    <StyledSlideArrow theme={theme} size={size} onClick={onClick} {...props}>
      {direction === "next" && <NextArrow width={svgSize} height={svgSize} />}
      {direction === "prev" && <PrevArrow width={svgSize} height={svgSize} />}
    </StyledSlideArrow>
  );
};

export default SlideArrowButton;
