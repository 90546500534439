import styled from "styled-components";
import { StyledSlideArrowProps, Theme, Size } from "./types";

const themeSet = (theme: Theme | undefined) => {
  switch (theme) {
    case "light":
      return `
      background-color: white;

      `;
    case "dark":
      return `
      background-color: black;
 
      `;
    default:
      return `
      background-color: #FFFFFF;

      `;
  }
};
const sizeSet = (size: Size | undefined) => {
  switch (size) {
    case "large":
      return `
        width: 48px;
        height:48px;
          `;
    case "medium":
      return `
      width: 48px;
        height:48px;
        `;
    case "small":
      return `
      width: 36px;
      height:36px;
   
        `;
    default:
      return `
      width: 48px;
        height:48px;
        `;
  }
};

export const StyledSlideArrow = styled.div<StyledSlideArrowProps>`
  ${(props) => sizeSet(props.size)};
  ${(props) => themeSet(props.theme)};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  cursor: pointer;
`;
