import {
  StyledWrapper,
  StyledContentWrapper,
  StyledContainer,
  StyledTitle,
  StyledImageWrapper,
  StyledContentCon,
  StyledComment,
  StyledInnerWrapper,
  StyledReverseContainer,
} from "./stlyes";
import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import Button from "../../../../components/button";

const AppFeature: React.FC = () => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledContainer>
          <StyledContentCon>
            <Button
              type="button"
              size="large"
              color="representative-reverse"
              text="제품 등록 지원"
              radius="5px"
              cursor={false}
            />
            <StyledContentWrapper>
              <StyledTitle>제품 등록을 손쉽게!</StyledTitle>
              <StyledComment>
                매번 다른 방식으로 발주를 받느라 어려움을 겪고 계신가요?
                <br /> 까다로운 등록 절차도 이지마스가 도와드리며,
                <br /> 모든 제품이 군부대에 잘 보이도록 체계적으로 관리됩니다.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
          <StyledImageWrapper>
            <picture>
              <source
                srcSet="/images/company/feature1.png"
                media="(min-width: 769px)"
              />
              <img src="/images/company/feature1M.png" alt="echobox1" />
            </picture>
          </StyledImageWrapper>
        </StyledContainer>
        <StyledReverseContainer>
          <StyledImageWrapper>
            <picture>
              <source
                srcSet="/images/company/feature2.png"
                media="(min-width: 769px)"
              />
              <img src="/images/company/feature2M.png" alt="echobox1" />
            </picture>
          </StyledImageWrapper>
          <StyledContentCon>
            <Button
              type="button"
              size="large"
              color="representative-reverse"
              text="발주 관리 자동화"
              radius="5px"
              cursor={false}
            />
            <StyledContentWrapper>
              <StyledTitle>발주부터 납품까지 한눈에!</StyledTitle>
              <StyledComment>
                발주 예정일, 수량, 납품 완료 여부까지 이지마스를 통해 <br />
                모든 과정이 한눈에 들어옵니다. 실수 없는 정확한 납품 <br />
                으로 신뢰를 높이세요.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
        </StyledReverseContainer>
        <StyledContainer>
          <StyledContentCon>
            <Button
              type="button"
              size="large"
              color="representative-reverse"
              text="고객 피드백 모니터링"
              radius="5px"
              cursor={false}
            />
            <StyledContentWrapper>
              <StyledTitle>고객의 목소리를 듣다</StyledTitle>
              <StyledComment>
                고객의 반응과 피드백을 실시간으로 확인하여
                <br /> 제품의 개선 방향을 파악하고,
                <br /> 더 나은 서비스와 품질을 제공합니다.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
          <StyledImageWrapper>
            <picture>
              <source
                srcSet="/images/company/feature3.png"
                media="(min-width: 769px)"
              />
              <img src="/images/company/feature3M.png" alt="echobox1" />
            </picture>
          </StyledImageWrapper>
        </StyledContainer>
        <StyledReverseContainer>
          <StyledImageWrapper>
            <picture>
              <source
                srcSet="/images/company/feature4.png"
                media="(min-width: 769px)"
              />
              <img src="/images/company/feature4M.png" alt="echobox1" />
            </picture>
          </StyledImageWrapper>
          <StyledContentCon>
            <Button
              type="button"
              size="large"
              color="representative-reverse"
              text="제품노출과 구매기회 확대"
              radius="5px"
              cursor={false}
            />
            <StyledContentWrapper>
              <StyledTitle>셀링포인트 강화</StyledTitle>
              <StyledComment>
                단순한 발주 시스템을 넘어, 여러분의 제품을 더욱 돋보 <br />
                이게 합니다. 신상품과 할인상품 안내, 맞춤형 배너 광고 <br />
                까지 유용한 상품 정보를 지속적으로 제공합니다.
              </StyledComment>
            </StyledContentWrapper>
          </StyledContentCon>
        </StyledReverseContainer>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default AppFeature;
