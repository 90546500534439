import Logo from "../logo";
import {
  StyledHeader,
  StyledLogoAndNavWrapper,
  StyledButtonWrapper,
  StyledSpan,
  StyledLogoWrapper,
  StyledMobileButtonWrapper,
  StyledClientButtonWrapper,
} from "./style";
import Button from "../button";
import { useNavigate } from "react-router-dom";
import usePathname from "../../hooks/use-pathname";

const Header = ({}) => {
  const { firstPath } = usePathname();
  const navigate = useNavigate();
  const handleNavi = () => {
    if (firstPath === "/") {
      navigate("/client");
    }
    if (firstPath === "/client") {
      navigate("/");
    }
  };
  return (
    <>
      <StyledHeader>
        <div>
          <StyledLogoAndNavWrapper>
            <StyledLogoWrapper>
              <Logo size="medium" />
              <StyledSpan onClick={handleNavi}>
                {firstPath === "/"
                  ? " 😃식품발주를 원하시나요?"
                  : "📦이지마스 입점을 원하시나요?"}
              </StyledSpan>
            </StyledLogoWrapper>
            {firstPath === "/" && (
              <StyledButtonWrapper>
                <Button
                  type="button"
                  color="representative"
                  size="small"
                  text="식품업체 입점하기"
                  onClick={() => navigate("/client")}
                />
                <Button
                  type="button"
                  color="lightBlack"
                  size="small"
                  text="앱소개서 다운로드"
                  onClick={() => alert("준비중입니다.")}
                />
              </StyledButtonWrapper>
            )}
            {firstPath === "/" && (
              <StyledMobileButtonWrapper>
                <Button
                  type="button"
                  color="representative"
                  size="small"
                  text="무료로 시작하기"
                  onClick={() => navigate("/")}
                />
              </StyledMobileButtonWrapper>
            )}
            {firstPath === "/client" && (
              <StyledClientButtonWrapper>
                <Button
                  type="button"
                  color="representative"
                  size="small"
                  text="무료로 시작하기"
                  onClick={() => navigate("/")}
                />
              </StyledClientButtonWrapper>
            )}
          </StyledLogoAndNavWrapper>
        </div>
      </StyledHeader>
    </>
  );
};

export default Header;
