import React, { ButtonHTMLAttributes } from "react";
import { StyledButton, StyledSpan } from "./style";
import { Size, Color, Variant, IconPosition } from "./types";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: Color;
  variant?: Variant;
  size?: Size;
  text: string;
  disabled?: boolean;
  icon?: string;
  iconPosition?: IconPosition;
  onClick?: () => void;
  radius?: "5px" | "200px";
  cursor?: boolean;
}

const Button = ({
  type = "button",
  color = "primary",
  size = "medium",
  text,
  variant = "fulled",
  icon,
  disabled,
  iconPosition,
  radius = "200px",
  cursor = true,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      type={type}
      {...props}
      size={size}
      color={color}
      variant={variant}
      disabled={disabled}
      icon={icon}
      iconPosition={iconPosition}
      radius={radius}
      cursor={cursor}
    >
      {icon && <span>{icon}</span>}
      <StyledSpan>{text}</StyledSpan>
    </StyledButton>
  );
};

export type { Variant, ButtonProps };
export default Button;
