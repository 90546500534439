import {
  Headline1,
  Headline1Orange,
  Paragraph1Gray,
} from "../../../../styles/typography";
import { css, keyframes, styled } from "styled-components";

const frameInAnimation = keyframes`
    0% {
         opacity: 0;
         transform: translateY(20%);
   
    }
    100%{
        opacity: 1;
        transform: translateY(10%);
       
    }
`;
interface Props {
  $isFadeActive?: boolean;
  $active?: 1 | 0;
}

const StyledWrapper = styled.section`
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: flex-start;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;

  padding-top: 109px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding-top: 40px;
    & > div {
      max-width: 768px;
      flex-direction: column;
      margin-top: 40px;
      & > img {
        width: 360px;
        height: 270px;
      }
    }
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0 20px;
    align-items: flex-start;
    & > img {
      width: 120px;
      height: 36px;
    }
    & > img:first-child {
      width: 100px;
      height: 34px;
    }
  }
`;

const StyledTitle = styled(Headline1)`
  white-space: pre-line;
  word-break: keep-all;
  @media screen and (max-width: 1520px) {
    font-size: 48px !important;
    line-height: 70px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 38px !important;
    line-height: 48px;
  }
`;

const StyledOrange = styled(Headline1Orange)`
  @media screen and (max-width: 1520px) {
    font-size: 48px !important;
    line-height: 70px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    white-space: nowrap;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 38px !important;
    line-height: 48px;
  }
`;

const StyledSubTitle = styled(Paragraph1Gray)`
  margin-top: 0px;
  @media screen and (max-width: 1520px) {
    font-size: 20px !important;
    white-space: nowrap;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 18px;
    line-height: 20px;
  }
`;

const StyledAppCon = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    margin-top: 20px;
  }
`;

const StyledImageCon = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 68px;
  transform: translateY(20%);

  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimation} 0.5s forwards ease-in-out;
    `}
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 42px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;

const StyledImageWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;
export {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledImageWrap,
};
