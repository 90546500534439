import {
  Button1,
  Headline2,
  Paragraph2,
  Paragraph2White,
} from "../../../../styles/typography";
import { styled, css } from "styled-components";

interface Props {
  $bg?: string;
}

const StyledWrapper = styled.section<Props>`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;
  background-image: url("/images/mainBanner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    background-image: url("/images/mainBannerM.png");
    height: 350px;
    gap: 30px;
    & > div {
      box-sizing: border-box;
      padding: 0 15px;
    }
  }
`;

const StyledContentCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    gap: 10px;
  }
`;

const StyledTitle = styled(Headline2)`
  text-align: center;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    white-space: nowrap;
  }
`;
const StyledComment = styled(Paragraph2White)`
  text-align: center;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    white-space: pre-line;
    word-break: keep-all;
    span {
      font-size: 22px;
      line-height: 34px;
    }
  }
`;
const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: block;
  }
`;
export {
  StyledWrapper,
  StyledContentCon,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledBr,
  StyledResponsiveBr,
};
