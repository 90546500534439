import theme from "../../../../styles/theme";
import {
  Button1,
  Headline2,
  Headline3White,
  Paragraph2,
} from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.presentative};
  box-sizing: border-box;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: 376px;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > div {
    margin-top: 42px;
    display: flex;
    gap: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    & > div {
      margin-top: 33px;
      & > img {
        width: 120px;
        height: 36px;
      }
      & > img:first-child {
        width: 100px;
        height: 34px;
      }
    }
  }
`;
const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;
const StyledTitle = styled(Headline3White)`
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;
  min-width: 310px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 20px !important;
  }
`;

const StyledLogoAndNavWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
`;
const StyledNav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 4%;
  ul {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

export {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledLogoAndNavWrapper,
  StyledNav,
  StyledBr,
  StyledResponsiveBr,
};
