import styled from "styled-components";

interface ReviewWrapperProps {
  $backgroundColor: string;
}

export const StyeldMainBestReviewWrapper = styled.div<ReviewWrapperProps>`
  width: 100%;
  box-sizing: border-box;
  padding: 88px 50px 150px 50px;
  background-color: ${(props) =>
    props.$backgroundColor
      ? props.$backgroundColor
      : `${({ theme }) => theme.color.g100}`};

  display: flex;
  justify-content: center;
  position: relative;
`;

export const StyledTitleText = styled.h1`
  font-size: 45px;
  line-height: 80px;
  font-weight: 700;
  letter-spacing: -0.4;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
  }
`;

export const StyledBestReviewInnerWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  gap: 43px;
`;

export const StyledBestReviewTitleWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  word-break: keep-all;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.laptop}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 966px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.laptop}) {
    flex-direction: row;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
    max-width: 550px;
  }
`;

export const StyledTitle = styled.div`
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    align-items: flex-start;
    white-space: nowrap;
    text-align: center;
  }
`;

export const StyledMobileButtonWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: flex;
    gap: 20px;
    align-items: flex-end;
  }
`;

export const StyledSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  left: 0%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.laptop}) {
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;

interface StyledSlideItemProsp {
  $isActive: boolean;
  $isCenter?: boolean;
}

export const StyledSlideItem = styled.div<StyledSlideItemProsp>`
  position: relative;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.3)};
  transition: opacity 0.5s ease;
  ${({ $isCenter }) => $isCenter && `opacity: 1;`}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StlyedPrevArrowWrapper = styled.div`
  opacity: 0.6;
`;

export const StyledSliderInnerWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 1920px;
`;

export const NextButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -5%;
  transform: translateY(-50%);
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
  }
`;

export const PrevButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -7%;
  transform: translateY(-50%);
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
  }
`;
