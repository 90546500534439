import {
  Headline1,
  Headline1Orange,
  Paragraph1Gray,
} from "../../../../styles/typography";
import { css, keyframes, styled } from "styled-components";

const frameInAnimation = keyframes`
    0% {
         opacity: 0;
         transform: translateY(20%);
   
    }
    100%{
        opacity: 1;
        transform: translateY(10%);
       
    }
`;
interface Props {
  $isFadeActive?: boolean;
  $active?: 1 | 0;
}

const StyledWrapper = styled.section`
  width: 100%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  overflow: hidden;
  padding: 180px 0 54px 0;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0px 0 150px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding-top: 40px;
  }
`;
const StyledInnerWrapper = styled.section<Props>`
  width: 100%;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  transform: translateY(20%);

  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimation} 0.5s forwards ease-in-out;
    `}

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;

const StyledOrange = styled(Headline1Orange)`
  @media screen and (max-width: 1520px) {
    font-size: 48px !important;
    line-height: 70px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    white-space: nowrap;
  }
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    align-items: center;
    gap: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    & > img {
      width: 120px;
      height: 36px;
    }
    & > img:first-child {
      width: 100px;
      height: 34px;
    }
  }
`;

const StyledTitle = styled(Headline1)`
  white-space: pre-line;
  word-break: keep-all;

  @media screen and (max-width: 1520px) {
    font-size: 48px !important;
    line-height: 70px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;

const StyledSubTitle = styled(Paragraph1Gray)`
  margin-top: 0px;
  @media screen and (max-width: 1520px) {
    font-size: 20px !important;
    white-space: nowrap;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    margin-top: 4px;
  }
`;

const StyledAppCon = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    margin-top: 20px;
  }
`;

const StyledImageCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 68px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 42px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;
export {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledInnerWrapper,
};
