import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  box-sizing: border-box;
  padding: 48px 0 41px 0;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.black};

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    height: 263px;
    padding: 20px 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    min-height: 223px;
    padding: 50px 40px;
    height: auto;
  }
`;
const StyledWrpper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
  }
`;
const StyeldInfoCon = styled.div``;
const StyledWebSiteInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  & > div {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;
const StyledLogoWrapper = styled.div`
  margin-bottom: 25px;
`;
const StyledTitle = styled.p`
  display: inline;
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 10px;
  }
`;
const StyledInfo = styled.p`
  display: inline;
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  color: ${({ theme }) => theme.color.g100};
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 10px;
  }
`;

const StyledInfoContour = styled.span`
  display: inline-block;
  margin: 0px 4px;
  background-color: ${({ theme }) => theme.color.g100};
  width: 1px;
  height: 15px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledInfoContour1 = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.color.g100};
  width: 1px;
  height: 13px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: 10px;
  }
`;

const StlyedPolicyWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
    gap: 12px;
    flex-direction: row;
  }
`;

const StyledPolicy = styled.p`
  display: inline;
  font-size: ${({ theme }) => theme.typography.size.s2}px;
  color: ${({ theme }) => theme.color.lightGray};
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.color.g100};
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    font-size: ${({ theme }) => theme.typography.size.s1}px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.s1}px;
  }
`;
const StyledCopyrightWrapper = styled.div``;
const StyledCopyright = styled.p`
  font-size: ${({ theme }) => theme.typography.size.s2}px;
  color: ${({ theme }) => theme.color.lightGray};
  line-height: 22px;
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: ${({ theme }) => theme.typography.size.s1}px;
    white-space: nowrap;
  }
`;

const StyledFlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    gap: 15px;
  }
`;
const StyledInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-height: 40px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-wrap: wrap;
  }
`;

const StlyedPolicyMobileWrapper = styled(StlyedPolicyWrapper)`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: block;
    display: flex;
    gap: 6px;
  }
`;

export {
  StyledFooter,
  StyledWrpper,
  StyledWebSiteInfoWrapper,
  StyledLogoWrapper,
  StyledTitle,
  StyledInfo,
  StyledInfoContour,
  StyledInfoContour1,
  StlyedPolicyWrapper,
  StyledPolicy,
  StyledCopyrightWrapper,
  StyledCopyright,
  StyledFlexWrapper,
  StyledInfoWrapper,
  StyeldInfoCon,
  StlyedPolicyMobileWrapper,
};
