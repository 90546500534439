import React from "react";
import {
  StyledInnerWrapper,
  StyledWrapper,
  StyledComment,
  StyledAccentComment,
  StyledContentWrapper,
  StyledNumbering,
  StyledFeatureText,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";

const ServiceComment = () => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledComment>
          🫡 MAS는 인터넷 기반이지만{" "}
          <StyledAccentComment>
            군부대는 인터넷 사용이 제한적입니다.
          </StyledAccentComment>
        </StyledComment>
        <StyledContentWrapper>
          <StyledNumbering>1</StyledNumbering>
          <StyledFeatureText>
            MAS에 등록된 제품을 쉽게
            <StyledResponsiveBr /> 모바일 수시 검색이 가능!
          </StyledFeatureText>
        </StyledContentWrapper>
        <StyledContentWrapper>
          <StyledNumbering>2</StyledNumbering>
          <StyledFeatureText>
            업체 담당자와 다이렉트로 연결!
            <StyledResponsiveBr />
            <StyledBr /> <StyledBr />
            📱전화, 💬카카오채널, ✉️쪽지 가능!
          </StyledFeatureText>
        </StyledContentWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default ServiceComment;
