import React from "react";
import AppDownload from "./components/app-download";
import AppSummary from "./components/app-summary";
import ServiceSummary from "./components/service-summary";
import AppFeature from "./components/app-feature";
import Banner from "./components/banner";
import Carousel from "./components/carousel";
import ServiceComment from "./components/service-comment";
import AppReview from "./components/app-review";
import { APP_REVIEW_LIST } from "./components/app-review/review";

const ClientBody = () => {
  return (
    <div style={{ overflow: "hidden", maxWidth: "100vw" }}>
      <AppDownload />
      <AppSummary />
      <ServiceSummary />
      <AppFeature />
      <AppReview text="이지마스 실제 사용후기" reviewList={APP_REVIEW_LIST} />
      <Carousel />
      <ServiceComment />
      <Banner />
    </div>
  );
};

export default ClientBody;
