import styled from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 215px;
  box-sizing: border-box;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0 20px;
    padding-bottom: 215px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0;
  }
`;
const StyledInnerWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.black};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 103px;
  gap: 20px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    max-width: 900px;
    padding: 86px 15px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    max-width: 100%;
    border-radius: 0px;
    gap: 10px;
    padding: 60px 0;
  }
`;

const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;

const StyledComment = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-weight: 700;
  font-size: 40px;
  line-height: 80px;
  letter-spacing: -0.4px;
  text-align: center;
  word-break: keep-all;
  white-space: pre-line;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 21px;
    line-height: 34px;
  }
`;

const StyledAccentComment = styled.span`
  position: relative;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #f88038;
  text-decoration-thickness: 7px;
  text-underline-offset: 10px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    text-decoration-thickness: 4px;
    text-underline-offset: 8px;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  justify-content: flex-start;
  box-sizing: border-box;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
    padding: 0 50px;
    gap: 15px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    flex-direction: column;
    padding: 0 50px;
    gap: 12px;
  }
`;

const StyledNumbering = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: #f88038;
  color: white;
  font-size: 40px;
  line-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    width: 50px;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
    padding: 10px;
    box-sizing: border-box;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    width: 33px;
    height: 33px;
    font-size: 20px;
    line-height: 30px;
  }
`;

const StyledFeatureText = styled.p`
  font-size: 34px;
  font-weight: 700;
  color: white;
  white-space: pre-line;
  word-break: keep-all;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
  }
`;
export {
  StyledWrapper,
  StyledInnerWrapper,
  StyledComment,
  StyledAccentComment,
  StyledContentWrapper,
  StyledNumbering,
  StyledFeatureText,
  StyledBr,
  StyledResponsiveBr,
};
