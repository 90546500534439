import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledImageWrap,
} from "./styles";

const AppDownload = () => {
  const { ref, isInViewport } = useScrollAnimation();

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledTitle>
          MAS를 손안에 <StyledOrange>EASY MAS</StyledOrange>
        </StyledTitle>
        <StyledSubTitle>군부대 식품발주 보조 통합서비스</StyledSubTitle>
        <StyledAppCon>
          <a
            href="https://apps.apple.com/kr/app/id6472038203"
            target="_blank"
          >
            <picture>
              <source
                srcSet="/images/app-store.png"
                media="(min-width: 769px)"
              />
              <img
                src="/images/app-store-mobile.png"
                alt="images/app-store.png"
              />
            </picture>
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=kr.easymas.buyer"
          >
            <picture>
              <source
                srcSet="/images/google-play.png"
                media="(min-width: 769px)"
              />
              <img
                src="/images/google-play-mobile.png"
                alt="images/google-play.png"
              />
            </picture>
          </a>
        </StyledAppCon>
      </StyledContentWrapper>
      <StyledImageCon ref={ref} $isFadeActive={isInViewport}>
        <img src="/images/company/main1.png" alt="mainSection1" />
      </StyledImageCon>
      <StyledImageWrap>
        <img src="/images/back.png" alt="mainSection1" width="100%" />
      </StyledImageWrap>
    </StyledWrapper>
  );
};

export default AppDownload;
