import React from "react";
import { Routes, Route } from "react-router-dom";
import useScrollObserver from "./hooks/use-move-scroll";
import theme from "./styles/theme";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CompanyPage from "./pages/company";
import ClientPage from "./pages/client";
import GlobalStyles from "./styles/globals";

function App() {
  const { wrapperRef, clickScrollNavigation, getActivateTarget } =
    useScrollObserver(["info", "funBox", "echoBox", "marketing"]);
  return (
    <StyledThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<CompanyPage />} />
        <Route path="/client" element={<ClientPage />} />
      </Routes>
      <GlobalStyles />
    </StyledThemeProvider>
  );
}

export default App;
