import { Headline4Bold } from "../../../../styles/typography";
import { keyframes } from "styled-components";
import { styled, css } from "styled-components";

const frameInAnimation = keyframes`
    0% {
         opacity: 0;
         transform: translateY(20%);
   
    }
    100%{
        opacity: 1;
        transform: translateY(10%);
       
    }
`;
const transXAnimation = keyframes`
    0% {
         
         transform: translateX(50%);
   
    }
    100%{
        
        transform: translateX(10%);
       
    }
`;

interface Props {
  $isFadeActive?: boolean;
  $isTransXActive?: boolean;
}

const StyledWrapper = styled.section`
  width: 100%;
  padding: 119px 0 165px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 68px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 60px 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 68px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;
const StyledContentWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  transform: translateY(20%);
  box-sizing: border-box;

  & > div {
    margin-top: 42px;
    display: flex;
    gap: 12px;
  }
  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimation} 0.5s forwards ease-in-out;
    `}
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0 60px;
    & > div {
      margin-top: 33px;
      & > img {
        width: 120px;
        height: 36px;
      }
      & > img:first-child {
        width: 100px;
        height: 34px;
      }
    }
  }
`;

const StyledTitle = styled(Headline4Bold)`
  text-align: center;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    font-size: 26px !important;
    line-height: 38px !important;
    font-weight: 700 !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px !important;
    line-height: 34px !important;
    font-weight: 700 !important;
    white-space: pre-line;
    word-break: keep-all;
  }
`;

const ItemRowStyle = css`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const StyledItemFirstRow = styled.div<Props>`
  ${ItemRowStyle}

  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${transXAnimation} 0.5s forwards ease-in-out;
    `}
`;
const StyledItemSecondtRow = styled.div<Props>`
  ${ItemRowStyle}
`;
const SlideAnimation = keyframes`
    0% {
      transform: translateX(0);
    }
    100%{
      transform: translateX(-25%);
    }
`;
const StyledItemList = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    gap: 12px;
  }
`;
const StyledSlider = styled.div`
  height: 84px;
  & > div {
    height: 60px;
    position: absolute;
    display: flex;
    gap: 16px;
    animation: ${SlideAnimation} 6s infinite linear;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: 48px;
  }
`;
const StyledReverseSlider = styled(StyledSlider)`
  & > div {
    animation: ${SlideAnimation} 6s infinite linear reverse;
  }
`;

export {
  StyledWrapper,
  StyledContainer,
  StyledContentWrapper,
  StyledTitle,
  StyledItemList,
  StyledItemFirstRow,
  StyledItemSecondtRow,
  StyledSlider,
  StyledReverseSlider,
};
