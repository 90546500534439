import { Headline2, Paragraph2 } from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  background-color: ${({ theme }) => theme.color.beige};
  padding: 200px 0px 150px 0px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;
const StyledInnerWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 180px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    max-width: 800px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    align-items: center;
    gap: 50px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 86px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    gap: 32px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;
const StyledReverseContainer = styled(StyledContainer)`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column-reverse;
  }
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
  }
`;

const StyledContentCon = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 18px;
  }
`;

const StyledTitle = styled(Headline2)`
  line-height: 80px;
  margin-top: 25px;
  margin-bottom: 44px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;
  }
`;
const StyledComment = styled(Paragraph2)`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    word-break: keep-all;
    white-space: pre-wrap;
  }
`;

const StyledImageWrapper = styled.div`
  width: 575px;
  height: 360px;
  border-radius: 15px;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    width: 326px;
    height: 200px;
    & > img {
      width: 326px;
      height: 200px;
    }
  }
`;

export {
  StyledWrapper,
  StyledContainer,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledImageWrapper,
  StyledContentCon,
  StyledInnerWrapper,
  StyledReverseContainer,
};
