const color = {
  primary: "#B47250",
  secondary: "#E0B276",
  black: "#212121",
  text: "#424242",
  white: "#ffffff",
  gray: "#BDBDBD",
  beige: "#F6EFE1",
  lightGray: "#CCCCCC",
  error: "#FF0000",
  representative: "#FF4D00",
  presentative: "#FF7E3B",
  funbox: "#57CB39",
  g900: "#686868",
  g800: "#424242",
  g600: "#757575",
  g400: "#BDBDBD",
  g200: "#EEEEEE",
  g100: "#999999",
  g000: "#E6E6E6",
  lightBlack: "#443E3B",
};

const typography = {
  weight: {
    regular: "400",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  size: {
    s1: 12,
    s2: 14,
    s3: 16,
    s4: 18,
    m1: 20,
    m2: 24,
    m3: 28,
    l1: 32,
    l2: 38,
    l3: 55,
    h1: 68,
  },
  family: {
    pretendard: "Pretendard Variable",
    seba: "HSBombaram 2.1",
    cormorantSC: "Cormorant SC, serif",
  },
};

const mediaScreen = {
  tablet: "1420px",
  mobile: "768px",
};

const theme = {
  color,
  typography,
  mediaScreen,
};

export default theme;
