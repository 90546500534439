import React from "react";
import {
  StyledTitle,
  StyledWrapper,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";

const AppSummary = () => {
  return (
    <StyledWrapper>
      <StyledTitle>
        “군부대 식품 발주, 이제 더 이상 복잡하지 않습니다!”
        <StyledResponsiveBr />
        <StyledBr /> 이지마스를 통해 군부대에 간편하게 제품을 알리고,{" "}
        <StyledResponsiveBr />
        <StyledBr />
        발주부터 납품까지 모든 과정을 효율적으로 관리하세요.
      </StyledTitle>
    </StyledWrapper>
  );
};

export default AppSummary;
