import { useNavigate } from "react-router-dom";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledComment,
  StyledContentCon,
  StyledBr,
  StyledResponsiveBr,
} from "./styles";
import Button from "../../../../components/button";

const Banner: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledContentCon>
          <StyledTitle>지금 바로 이지마스에 등록하세요</StyledTitle>
          <StyledComment>
            군부대와의 더 나은 소통과 효율적인 발주 관리를 원하신다면,{" "}
            <StyledBr />
            <StyledResponsiveBr />
            이지마스가 그 해결책입니다. <StyledResponsiveBr />
            지금 바로 이지마스 입점을통해 군부대에 제품을 알려보세요!
          </StyledComment>
        </StyledContentCon>
        <div>
          <Button
            onClick={() => window.open('https://vendor.easymas.kr', '_blank')}
            type="button"
            size="full"
            color="representative"
            text="3초만에 이지마스 입장하기"
          />
        </div>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

export default Banner;
