import { useRef, useState, useEffect } from "react";

export const useScrollAnimation = () => {
  const [isInViewport, setIsInViewPort] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isInViewport) {
          setIsInViewPort(true);
        }
      });
    };
    const options = {
      root: null,
      rootMargin: "0px 0px 0px 50px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);
  return {
    isInViewport,
    ref,
  };
};
