// src/pages/HomePage.js
import { Helmet } from "react-helmet";
import RootLayout from "../../components/root-layout";
import ClientSideComponent from "../../components/client-side-component";

import useScrollObserver from "../../hooks/use-move-scroll";
import CompanyBody from "../../page-bodies/company";

const CompanyPage = () => {
  const { wrapperRef, clickScrollNavigation, getActivateTarget } =
    useScrollObserver([]);

  return (
    <>
      <Helmet>
        <title>EasyMas</title>
        <meta name="description" content="Mas를 손안에 EASY MAS" />
      </Helmet>
      <ClientSideComponent>
        <div ref={wrapperRef}>
          <RootLayout>
            <CompanyBody />
          </RootLayout>
        </div>
      </ClientSideComponent>
    </>
  );
};

export default CompanyPage;
