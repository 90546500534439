import { useScrollAnimation } from "../../../../hooks/use-scroll-animation";
import {
  StyledWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledOrange,
  StyledAppCon,
  StyledImageCon,
  StyledInnerWrapper,
} from "./styles";

const AppDownload = () => {
  const { ref, isInViewport } = useScrollAnimation();

  return (
    <StyledWrapper>
      <StyledInnerWrapper ref={ref} $isFadeActive={isInViewport}>
        <StyledContentWrapper>
          <StyledTitle>
            MAS를 손안에
            <br /> <StyledOrange>EASY MAS</StyledOrange>
          </StyledTitle>
          <StyledSubTitle>군 급식 발주, 이지마스로 더 간편하게!</StyledSubTitle>
          <StyledAppCon>
            <a
              href="https://apps.apple.com/kr/app/id6472038203"
              target="_blank"
            >
              <picture>
                <source
                  srcSet="/images/app-store.png"
                  media="(min-width: 769px)"
                />
                <img
                  src="/images/app-store-mobile.png"
                  alt="images/app-store.png"
                />
              </picture>
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=kr.easymas.buyer"
            >
              <picture>
                <source
                  srcSet="/images/google-play.png"
                  media="(min-width: 769px)"
                />
                <img
                  src="/images/google-play-mobile.png"
                  alt="images/google-play.png"
                />
              </picture>
            </a>
          </StyledAppCon>
        </StyledContentWrapper>
        <StyledImageCon>
          <img src="/images/client/main1.png" alt="mainSection1" />
        </StyledImageCon>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

export default AppDownload;
