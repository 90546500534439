import { Headline3White, Paragraph2 } from "../../../../styles/typography";
import { styled } from "styled-components";

const StyledWrapper = styled.section`
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.black};
  box-sizing: border-box;
  gap: 60px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    height: 530px;
    padding: 0 18px;
    gap: 40px;
  }
`;

const StyledTitle = styled(Headline3White)`
  text-align: center;
  white-space: pre-line;
  word-break: keep-all;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }
`;

const StyledAccentTitle = styled.span`
  background-color: ${({ theme }) => theme.color.representative};
  padding: 10px;
  border-radius: 5px;
  letter-spacing: -0.4px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    background-color: ${({ theme }) => theme.color.black};
  }
`;

const StyledComment = styled(Paragraph2)`
  color: ${({ theme }) => theme.color.white};
  text-align: center;

  letter-spacing: -1px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 18px !important;
    line-height: 34px !important;
  }
`;
const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;
export {
  StyledWrapper,
  StyledTitle,
  StyledAccentTitle,
  StyledComment,
  StyledBr,
  StyledResponsiveBr,
};
