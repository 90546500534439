import { Button1, Headline2, Paragraph2 } from "../../../../styles/typography";
import { keyframes } from "styled-components";
import { styled, css } from "styled-components";

const frameInAnimationTop = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
`;

const frameInAnimationBottom = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
`;

interface Props {
  $isFadeActive?: boolean;
  $active?: 1 | 0;
}
const StyledMainWrapper = styled.section`
  display: flex;
  gap: 150px;
  justify-content: center;
  overflow: hidden;
  padding-top: 180px;
  padding-bottom: 250px;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 60px;
    align-items: center;
    gap: 42px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    flex-direction: column;
    align-items: center;
    gap: 42px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;
const StyledWrapperTop = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 68px;
  transform: translateY(20%);
  justify-content: center;
  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimationTop} 0.8s forwards ease-in-out;
    `}

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    max-width: 768px;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    gap: 42px;
    & > img {
      width: 360px;
      height: 270px;
    }
  }
`;
const StyledWrapperBottom = styled(StyledWrapperTop)`
  transform: translateY(-20%);
  ${(props) =>
    props.$isFadeActive &&
    css`
      animation: ${frameInAnimationBottom} 0.8s forwards ease-in-out;
    `}
`;

const StyledContentWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    margin-top: 33px;
    width: 100vw;
    align-items: center;
  }
`;
const StyledContentWrapper2 = styled(StyledContentWrapper1)`
  align-items: flex-end;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
  }
`;

const StyledImageWrapper = styled.div`
  @media screen and (max-width: 450px) {
    align-self: center;
    padding-right: 12%;
  }
  @media screen and (max-width: 360px) {
    align-self: flex-start;
  }
`;

const StyledTitle = styled(Headline2)`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }
`;

const StyledSubTitle = styled(Paragraph2)`
  text-align: start;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    margin-top: 4px;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    word-break: keep-all;
    white-space: pre-line;
  }
`;
const StyledBr = styled.br`
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

const StyledResponsiveBr = styled.br`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;
export {
  StyledMainWrapper,
  StyledWrapperTop,
  StyledWrapperBottom,
  StyledContentWrapper1,
  StyledContentWrapper2,
  StyledTitle,
  StyledSubTitle,
  StyledBr,
  StyledResponsiveBr,
  StyledImageWrapper,
};
