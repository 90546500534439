import styled, { keyframes, css } from "styled-components";

interface NavItemProps {
  active?: number;
  $activeNav?: string;
}
const frameInAnimation = keyframes`
    0% {
         opacity: 0;
       
   
    }
    100%{
        opacity: 1;
      
       
    }
`;
const StyledHeader = styled.header`
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  height: 68px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    background-color:;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    padding: 0 20px;
    height: 60px;
    & > div {
      max-width: 1000px;
      padding: 0 20px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    padding: 0;
    max-width: 768px;
    height: 60px;
    & > div {
      padding: 0 20px;
    }
  }
`;

const StyledLogoAndNavWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  gap: 27px;
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;
const StyledMobileButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: block;
  }
`;
const StyledClientButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
  }
`;

const StyledSpan = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    display: none;
  }
`;
const StyledNav = styled.nav<NavItemProps>`
  height: 100%;
  display: flex;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;

    ${(props) =>
      props.$activeNav == "main" &&
      css`
        animation: ${frameInAnimation} 0.5s forwards ease-in-out;
      `}
    ${(props) =>
      props.$activeNav == "marketing" &&
      css`
        animation: ${frameInAnimation} 0.5s forwards ease-in-out;
      `}
  }
  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: none;
  }
`;

interface StyledNavItemProps {
  $active: 1 | 0;
}
const StyledNavItem = styled.li<StyledNavItemProps>`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.size.s3}px;
  line-height: 19px;
  cursor: pointer;
  color: ${({ $active, theme }) =>
    $active ? theme.color.representative : theme.color.black};
`;
// User

const StyledUserWrapper = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 6px;
    margin-bottom: 2px;
    font-size: 20px;
  }
`;

const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  :is(a, button) {
    font-family: ${({ theme }) => theme.typography.family.pretendard};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.color.black};
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    gap: 12px;

    :is(a, button) {
      font-size: 14px;
    }
  }
`;

const StyledStick = styled.hr`
  width: 1px;
  height: 13px;
  border: none;
  background-color: ${({ theme }) => theme.color.black};
  opacity: 0.4;
`;
const StyledMobileMenuController = styled.div`
  display: none;

  svg {
    margin-left: 24px;
    font-size: 24px;
    cursor: pointer;
    color: ${({ theme }) => theme.color.black};
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    display: flex;
  }
`;

// PcSubMenu

const StyledPcSubMenuWrapper = styled.nav`
  position: fixed;
  left: 100%;
  top: 79px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.color.gray};
  border-bottom: 0px solid ${({ theme }) => theme.color.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  overflow: hidden;
  transition: all 0.25s ease;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray};
    height: 138px;
  }

  & > div {
    max-width: 1520px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 24px 40px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    &:first-child {
      margin-left: 221px;
      width: 83px;
    }

    &:last-child {
      margin-left: 291px;
      width: 56px;
    }
  }
`;

const StyledPcSubMenuItem = styled.li<NavItemProps>`
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme, active }) => (active ? theme.color.primary : "#424242")};
`;

// MobileSubMenu

interface StyledMobileSubMenuWrapperProps {
  active: number;
}

const StyledMobileSubMenuWrapper = styled.section<StyledMobileSubMenuWrapperProps>`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  z-index: 9;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 55%,
    rgba(255, 117, 17, 1) 97%,
    rgba(255, 107, 0, 1) 100%
  );
  top: 60px;
  overflow: hidden;
  max-height: 0;
  display: none;
  transition: max-height 0.4s ease-in-out;

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.tablet}) {
    max-height: ${({ active }) => (active ? "100vh" : "0px")};
    display: block;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    top: -10px;
    margin-top: 64px;

    max-height: ${({ active }) => (active ? "100vh" : "0px")};
    display: block;
  }
`;

const StyledMobileRootMenuItem = styled.li<NavItemProps>`
  box-sizing: border-box;
  & > li {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    padding: 14px 20px;
    line-height: 28px;
    font-size: ${({ theme }) => theme.typography.size.m1}px;
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    color: ${({ theme, active }) =>
      active ? theme.color.primary : theme.color.black};
  }
  & > a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 14px 20px;
    line-height: 28px;
    font-size: ${({ theme }) => theme.typography.size.m1}px;
    font-weight: ${({ theme }) => theme.typography.weight.bold};
    color: ${({ theme, active }) =>
      active ? theme.color.primary : theme.color.black};
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 16px;
    line-height: 24px;

    & > a {
    }
  }
`;

const StyledMobileSubMenuItem = styled.li<NavItemProps>`
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.typography.family.pretendard};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* padding: 12px 40px; */
  color: ${({ theme, active }) =>
    active ? theme.color.primary : theme.color.black};

  a {
    display: block;
    padding: 12px 40px;
  }

  @media screen and (max-width: ${({ theme }) => theme.mediaScreen.mobile}) {
    font-size: 14px;
    line-height: 20px;

    a {
      padding: 8px 40px;
    }
  }
`;

export {
  StyledHeader,
  StyledLogoAndNavWrapper,
  StyledNav,
  StyledNavItem,
  StyledUserWrapper,
  StyledLinkWrapper,
  StyledStick,
  StyledMobileMenuController,
  StyledPcSubMenuWrapper,
  StyledPcSubMenuItem,
  StyledMobileSubMenuWrapper,
  StyledMobileRootMenuItem,
  StyledMobileSubMenuItem,
  StyledButtonWrapper,
  StyledSpan,
  StyledLogoWrapper,
  StyledMobileButtonWrapper,
  StyledClientButtonWrapper,
};
